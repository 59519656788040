<template>
  <div>
    <p>404 - Not Found</p>
  </div>
</template>

<script>
export default {
  name: "404"
};
</script>
